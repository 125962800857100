import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById } from "ticketino-api-client";
import { Calendar } from "react-calendar";
import { toZonedTime } from 'date-fns-tz';

import Loading from "./components/Loading";
import logo from "../img/illuminarium.png";

import "../css/style.css";
import "../css/calendar.css";

const Step10_TicketCalendar = () => {
    const [date, setDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [eventGroup, setEventGroup] = useState({});
    const [orderId, setOrderId] = useState();
    const [eventDates, setEventDates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedEventId, setSelectedEventId] = useState(0);
    const [selectedKombiYukiEventId, setSelectedKombiYukiEventId] = useState(0);
    const [selectedKombiDigitalEventId, setSelectedKombiDigitalEventId] = useState(0);

    const [eventGroupIdYukiShow, setEventGroupIdYukiShow] = useState(0);
    const [eventGroupIdDigitalArtShow, setEventGroupIdDigitalArtShow] = useState(0);
    const [eventGroupIdKombiTickets, setEventGroupIdKombiTickets] = useState(0);

    const timeZone = 'Europe/Zurich';
    const today = new Date();
    const zurichDate = toZonedTime(today, timeZone);  // Convert to Zurich time

    const defaultMonth = zurichDate.getMonth() < 11 ? 10 : 11; // Default month to November or December
    const [currentMonth, setCurrentMonth] = useState(new Date(zurichDate.getFullYear(), defaultMonth, 1));

    const { eventGroupId, kombiTicketsQuantity, language } = useParams();

    let navigate = useNavigate();
    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {
        const loadToken = async () => {
            try {
                const res = await axios.get("form/token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
                api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
                sessionStorage.setItem("token", res.data);
                requestFormSettings();
            } catch (error) {
                console.error(error);
            }
        };

        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);
                startOrder(res.data.posId);
                setEventGroupIds(res.data);
            } catch (error) {
                console.error(error);
            }
        };

        const setEventGroupIds = (data) => {
            setEventGroupIdYukiShow(data.eventGroupIdYukiShow);
            setEventGroupIdDigitalArtShow(data.eventGroupIdDigitalArtShow);
            setEventGroupIdKombiTickets(data.eventGroupIdKombiTickets);
        };

        const requestResources = async () => {
            try {
                const res = await axios.get(`form/resources/${language}`);
                setResources(res.data?.translation);
            } catch (error) {
                console.error(error);
            }
        };

        sessionStorage.setItem("kombiTicketsQuantity", kombiTicketsQuantity);

        loadToken();
        requestResources();
    }, [language]);

    useEffect(() => {
        if (orderId) {
            loadEvents(eventGroupId, currentMonth);
        }
    }, [currentMonth]);

    const startOrder = async (posId) => {
        const order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const res = await axios.post(`${baseUrl}/ShopBasket/Order`, order);
            sessionStorage.setItem("OrderId", res.data.id);
            setOrderId(res.data.id);
            setCurrentMonth(new Date(zurichDate.getFullYear(), defaultMonth, 1));
        } catch (error) {
            console.error(error);
        }
    };

    const loadEvents = async (eventGroupId, currentMonth) => {
        setLoading(true);

        try {
            // EventGroup
            const eventGroup = await axios.get(`${baseUrl}/EventGroup/${eventGroupId}`);

            const totalNumberOfEventsResponse = await axios.get(`${baseUrl}/EventGroup/${eventGroupId}/Events/Count`);
            const totalNumberOfEvents = totalNumberOfEventsResponse.data;
            const eventBatchSize = 100;
            let events = [];

            for (let i = 0; i <= totalNumberOfEvents; i += eventBatchSize) {
                const eventGroupEventsResponse = await axios.get(`${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupId}&languageCode=${language}&numberToTake=${eventBatchSize}&numberToSkip=${i}`);
                events = events.concat(eventGroupEventsResponse.data?.events);
            }

            // Check if the event date is within the current month and in the future
            const eventsForCurrentMonth = events.filter((event) => {
                const eventDate = new Date(event.start);
                const startOfMonth = new Date(Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth(), 1));
                const endOfMonth = new Date(Date.UTC(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
                return eventDate >= startOfMonth && eventDate < endOfMonth && eventDate >= today;
            });

            const datesArray = [];

            await Promise.all(eventsForCurrentMonth.map(async (event) => {
                const ticketCountResponse = await requestTicketCount(event.id);
                const theEvent = await getEventById(event.id);

                let soldOut = false;

                // for kombi
                if (eventGroupId == eventGroupIdKombiTickets) {
                    const totalAvailable = theEvent?.maxTickets - ticketCountResponse;
                    soldOut = totalAvailable < kombiTicketsQuantity;
                } else {
                    soldOut = (theEvent?.maxTickets - ticketCountResponse) <= 0;
                }

                if (!soldOut) {
                    datesArray.push(event.start?.split('T')[0]);
                }

                event.end = theEvent?.end;
                event.isSoldOut = soldOut;
                event.maxTickets = theEvent?.maxTickets;
                event.ticketCount = theEvent?.maxTickets - ticketCountResponse;
                event.eventGroupId = event.name.indexOf('YUKI') !== -1 ? eventGroupIdYukiShow : eventGroupIdDigitalArtShow;

            })).then(() => {
                setEventDates(datesArray);
                setEvents(events);
                setEventGroup(eventGroup.data)
                setLoading(false);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const requestTicketCount = async (id) => {
        try {
            const res = await axios.get(`${baseUrl}/Event/${id}/TicketsSold`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    };

    const tileClassName = ({ date }) => {
        // Create a UTC date from the input
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        // Format the date as ISO string in UTC
        const formattedDate = utcDate.toISOString().split('T')[0];
        return eventDates.includes(formattedDate) ? 'bold-date' : 'disabled-date';
    };

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        setCurrentMonth(activeStartDate);

        // clear old values
        setSelectedDate("");
        setSelectedEventId(0);
        setSelectedKombiYukiEventId(0);
        setSelectedKombiDigitalEventId(0);
    };

    const onClickDay = (date) => {
        let selectedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const formattedDate = selectedDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);

        // clear old values
        setSelectedEventId(0);
        setSelectedKombiYukiEventId(0);
        setSelectedKombiDigitalEventId(0);
    };

    const onCheckedChange = (e) => setSelectedEventId(e.target.value);
    const onKombiYukiCheckedChange = (e) => setSelectedKombiYukiEventId(e.target.value);
    const onKombiDigitalCheckedChange = (e) => setSelectedKombiDigitalEventId(e.target.value);

    const mapEventStartTimes = () => {
        const currentEventsByDate = events.filter(e => e.start?.split('T')[0] === selectedDate);

        return (
            <>
                {resources && eventGroupId && eventGroupIdYukiShow &&
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {eventGroupId == eventGroupIdYukiShow ? resources?.TicketCalendarYukiDescription : resources?.TicketCalendarDigitalDescription}
                        </div>
                    </div>
                }
                {currentEventsByDate?.length > 0 && currentEventsByDate.map((currentEvent) => {
                    const eventDateStart = new Date(currentEvent.start);
                    const eventTimeStart = formatTime(eventDateStart);

                    const eventDateEnd = new Date(currentEvent.end);
                    const eventTimeEnd = formatTime(eventDateEnd);

                    //let description = eventTime + " Uhr - " + currentEvent.ticketCount + "/" + currentEvent.maxTickets;
                    let description = eventTimeStart + " - " + eventTimeEnd + " Uhr";

                    if (currentEvent.isSoldOut) {
                        description = eventTimeStart + " - " + eventTimeEnd + " Uhr - AUSVERKAUFT";
                    }

                    return (
                        <div key={currentEvent.id} className="row mt-4">
                            <div key={currentEvent.id} className="col-md-12 text-center">
                                <div className="form-group">
                                    <input
                                        className="mt-2"
                                        type="radio"
                                        id={"start-" + currentEvent.id}
                                        name="event-start"
                                        value={currentEvent.id}
                                        onChange={(e) => onCheckedChange(e)}
                                        disabled={currentEvent.isSoldOut}
                                        required
                                    />
                                    <label className="ms-3 f-size-20px label-radio" htmlFor={"start-" + currentEvent.id}>
                                        {description}
                                    </label>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    // only for kombi
    const mapKombiEventStartTimes = () => {
        const currentEventsByDate = events.filter(e => e.start?.split('T')[0] === selectedDate);
        const yukiShow = currentEventsByDate.filter(e => e.eventGroupId === eventGroupIdYukiShow);
        const digitalArtShow = currentEventsByDate.filter(e => e.eventGroupId === eventGroupIdDigitalArtShow);

        return (
            <div className="row">
                <div className="offset-md-1 col-md-6 mt-4">
                    <div className="col-md-10 min-height-slot-description">{resources && resources?.TicketCalendarYukiDescription}</div>
                    {yukiShow?.length > 0 &&
                        yukiShow.map((currentEvent) => {
                            const eventDateStart = new Date(currentEvent.start);
                            const eventTimeStart = formatTime(eventDateStart);

                            const eventDateEnd = new Date(currentEvent.end);
                            const eventTimeEnd = formatTime(eventDateEnd);

                            const eventName = currentEvent.name.replace("ILLUMINARIUM - ", "");

                            //let description = eventTime + " Uhr - " + currentEvent.ticketCount + "/" + currentEvent.maxTickets;
                            let description = eventTimeStart + " - " + eventTimeEnd + " Uhr";

                            if (currentEvent.isSoldOut) {
                                description = eventTimeStart + " - " + eventTimeEnd + " Uhr - AUSVERKAUFT";
                            }

                            return (
                                <div key={currentEvent.id}>
                                    <div className="form-group d-flex mt-4">
                                        <input
                                            className="mt-2"
                                            type="radio"
                                            id={"start-" + currentEvent.id}
                                            name="yuki-show"
                                            value={currentEvent.id}
                                            onChange={(e) => onKombiYukiCheckedChange(e)}
                                            disabled={currentEvent.isSoldOut}
                                            required
                                        />
                                        <label className="ms-3 f-size-20px label-radio" htmlFor={"start-" + currentEvent.id}>
                                            {description}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="col-md-5 mt-4">
                {resources && 
                    <div className="min-height-slot-description">
                        {resources?.TicketCalendarDigitalDescription}
                    </div>
                }
                    {digitalArtShow?.length > 0 &&
                        digitalArtShow.map((currentEvent) => {
                            const eventDateStart = new Date(currentEvent.start);
                            const eventTimeStart = formatTime(eventDateStart);

                            const eventDateEnd = new Date(currentEvent.end);
                            const eventTimeEnd = formatTime(eventDateEnd);

                            const eventName = currentEvent.name.replace("ILLUMINARIUM - ", "");

                            //let description = eventTime + " Uhr - " + currentEvent.ticketCount + "/" + currentEvent.maxTickets;
                            let description = eventTimeStart + " - " + eventTimeEnd + " Uhr ";

                            if (currentEvent.isSoldOut) {
                                description = eventTimeStart + " - " + eventTimeEnd + " Uhr - AUSVERKAUFT";
                            }

                            return (
                                <div key={currentEvent.id}>
                                    <div className="form-group d-flex mt-4">
                                        <input
                                            className="mt-2"
                                            type="radio"
                                            id={"start-" + currentEvent.id}
                                            name="digital-art-show"
                                            value={currentEvent.id}
                                            onChange={(e) => onKombiDigitalCheckedChange(e)}
                                            disabled={currentEvent.isSoldOut}
                                            required
                                        />
                                        <label className="ms-3 f-size-20px label-radio" htmlFor={"start-" + currentEvent.id}>
                                            {description}
                                        </label>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    const formatTime = (date) => {
        // Convert the date to Europe/Zurich time and format it to 24-hour format
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'Europe/Zurich' // Ensure the time is in Swiss time zone
        };
        return new Intl.DateTimeFormat('de-CH', options).format(date);
    };

    return (
        <div>
            {loading && <Loading alignment="center" color="#d3d3d3" bgColor="transparent" position="fixed" top="300px" />}
            <>
                {resources && (
                    <div className="container">
                        <div className="row">

                            <div className="col-md-3 text-center">
                                <img className="img-fluid logo" src={logo} alt="img" />
                            </div>

                            <div className="offset-md-1 col-md-4">
                                {eventGroup && eventGroup.name &&
                                    <div className="row mt-5">
                                        <div className="col-md-12 text-center">
                                            <h2>TICKETKALENDER {eventGroup.name}</h2>
                                        </div>
                                    </div>
                                }
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className={loading ? "isHidden" : ""}>
                                            <Calendar
                                                view="month"
                                                value={date}
                                                onChange={setDate}
                                                tileClassName={tileClassName}
                                                onClickDay={onClickDay}
                                                locale={language}
                                                minDate={new Date(2024, defaultMonth, 1)}
                                                maxDate={new Date(2024, 11, 31)}
                                                onActiveStartDateChange={handleActiveStartDateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="offset-md-3 col-md-6">
                                {!loading && selectedDate && (
                                    <div>
                                        <div className="row mt-5">
                                            <div className="col-md-6 f-size-20px text-uppercase">
                                                <p>{resources?.TicketCalendarChooseTime}</p>
                                            </div>
                                        </div>
                                        {eventGroupId == eventGroupIdKombiTickets ? mapKombiEventStartTimes() : mapEventStartTimes()}
                                    </div>
                                )}
                                <div className="row mt-5 mb-4">
                                    <div className="col-6">
                                        <button className="btn custom-button" onClick={() => navigate(-1)}>
                                            {resources?._Back}
                                        </button>
                                    </div>
                                    <div className="col-6 text-end">
                                        {eventGroupId != eventGroupIdKombiTickets ? (
                                            <button
                                                className="btn custom-button"
                                                disabled={!selectedEventId > 0}
                                                onClick={() => navigate(`/${language}/tickets/${eventGroupId}/${selectedEventId}`)}
                                            >
                                                WEITER
                                            </button>
                                        ) : (
                                            <button
                                                className="btn custom-button"
                                                disabled={!selectedKombiYukiEventId > 0 || !selectedKombiDigitalEventId > 0}
                                                onClick={() =>
                                                    navigate(`/${language}/tickets/${eventGroupId}/${selectedKombiYukiEventId}/${selectedKombiDigitalEventId}/${kombiTicketsQuantity}`)
                                                }
                                            >
                                                WEITER
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default Step10_TicketCalendar;